import React from "react"
import { lazy } from "react"
import { Navigate, Outlet, useRoutes } from "react-router-dom"
import { useLocation, useNavigate } from "react-router"
import Home from "../components/common/Home"
import NotificationSettings from "../components/settings/NotificationSettings"
import ProfileSettings from "../components/settings/ProfileSettings"
import Builder from "../components/guide/Builder"
import { Dashboard, Domain } from "@mui/icons-material"
import AdminList from "../components/organization/AdminList"
import AdminPage from "../components/organization/AdminPage"
import Translater from "../components/multilingual/Multilingual"
import DomainSettings from "../components/settings/DomainSettings"
import { useAuth } from "../components/auth/AuthProvider"
import CodeInstall from "../components/settings/InstallSettings"
import Audience from "../components/audience/Audience"
import Tours from "../components/tours/Tours"
import Announcements from "../components/announcements/Announcements"
import Banners from "../components/banners/Banners"
import Tooltip from "../components/tooltips/Tooltips"
import Hotspots from "../components/hotspots/Hotspots"
import Checklists from "../components/checklists/Checklists"
import Surveys from "../components/surveys/Survey"
import ProtectedRoute from "./ProtectedRoute"
import { useParams } from "react-router"
//import { toLanguage } from "../components/adminMenu/AdminMenu"
const AccountCreate = lazy(() => import("../components/account/AccountCreate"));
const AuditLogList = lazy(() => import("../components/auditLog/AuditLogList"));
const Login = lazy(() => import("../components/login/login"));
const OrganizationList = lazy(() => import("../components/organization/OrganizationList"));
const AccountsList = lazy(() => import("../components/account/AccountList"));
const GuidesList = lazy(() => import("../components/guide/GuideList"));
const UserList = lazy(() => import("../components/user/UserList"));
const Teamsetting = lazy(() => import("../components/settings/TeamSettings"));
const Settings = lazy(() => import("../components/settings/Settings"));
const InstallSetting = lazy(() => import("../components/settings/InstallSettings"));
const BillingSetting = lazy(() => import("../components/settings/BillingSettings"));
const RightSettings = lazy(() => import("../components/settings/RightSettings"));
const SuperAdminAuditLogList = lazy(() => import("../components/auditLog/SuperAdminAuditLogList"));
const Callback = lazy(() => import("../services/Callback"));
const DashBoard = lazy(() => import("../components/dashboard/Dashboard"));
const FileList = lazy(() => import("../components/fileManagement/FileList"));
const ThemeSettings = lazy(() => import("../components/settings/ThemeSettings"));
const Forgotpassword = lazy(() => import("../components/login/Forgotpassword"));
const ResetPassword = lazy(() => import("../components/login/ResetPassword"));
const AdminLoginPage = lazy(() => import("../components/login/Superadminloginpage"));
const ExpiredLink = lazy(() => import("../components/login/Expiredlink"));
// const AnnouncementSettings = lazy(() => import("../components/announcements/AnnouncementSettings"));
const WebAppSettingspage = lazy(() => import("../components/webappsettingspage/WebAppSettings"));
// const TooltipSettings = lazy(() => import("../components/tooltips/TooltipSettings"));
// const ToursSettings = lazy(() => import("../components/tours/ToursSettings"));
const Routing = () => {
	const { user, userDetails } = useAuth();
	const { passwordLogId } = useParams();
	// const initialBanner: Banner = {
  const routes = useRoutes([
		// Public routes
		{
			path: "/login",
			element: <Login />,
		},

		{
			path: "/callback",
			element: <Callback />,
		},
		{
			path: "/forgotpassword",
			element: <Forgotpassword />,
		},

		{
			path: "/resetpassword/:passwordLogId",
			element: <ResetPassword />,
		},
		{
			path: "/linkexpired",
			element: <ExpiredLink />,
		},
		{
			path: "/admin/adminlogin",
			element: <AdminLoginPage />,
		},
		{
			path: "*",
			element: <ProtectedRoute allowedUserTypes={["admin", "user", "superadmin"]} />,
			children: [{ path: "", element: <Home /> }],
		},

		// Protected routes for Admin and User
		{
			path: "/dashboard",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Dashboard /> }],
		},
		{
			path: "/settings",
			element: <ProtectedRoute allowedUserTypes={["admin"]} />,
			children: [{ path: "", element: <Settings /> }],
		},
		{
			path: "/auditlog",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <AuditLogList /> }],
		},
		{
			path: "superadmin",
			element: <ProtectedRoute allowedUserTypes={["admin", "superadmin"]} />,
			children: [
				{
					path: "organizations",
					element: <OrganizationList />,
				},
				{
					path: "*",
					element: <OrganizationList />,
				},
				{
					path: ":organizationId/createadmin",
					element: <AdminPage />,
				},
				{
					path: "adminList",
					element: <AdminList />,
				},
				{
					path: "auditlogs",
					element: <SuperAdminAuditLogList />,
				},
			],
		},
		{
			path: "/createaccount",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <AccountCreate /> }],
		},
		{
			path: "/guides",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <GuidesList /> }],
		},
		{
			path: "/:organizationId",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [
				{
					path: "accounts",
					element: <AccountsList />,
				},
				{
					path: "team",
					element: <UserList />,
				},
			],
		},

		{
			path: "/user",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <UserList /> }],
		},
		{
			path: "/filelist",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <FileList /> }],
		},
		{
			path: "/guide",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <GuidesList /> }],
		},
		{
			path: "/dashboard",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <DashBoard /> }],
		},
		{
			path: "/ThemeSettings",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <ThemeSettings /> }],
		},
		{
			path: "/activitylog",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <AuditLogList /> }],
		},

		{
			path: "/settings",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [
				{
					path: "rights",
					element: <RightSettings />,
				},
				{
					path: "domain",
					element: <DomainSettings />,
				},
				{
					path: "install",
					element: <InstallSetting />,
				},
				{
					path: "domain",
					element: <DomainSettings />,
				},
				{
					path: "notifications",
					element: <NotificationSettings />,
				},
				{
					path: "billing",
					element: <BillingSetting />,
				},
			],
		},

		{
			path: "/Builder",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Builder /> }],
		},

		{
			path: "/audience",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Audience /> }],
		},
		{
			path: "/tours",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Tours /> }],
		},
		{
			path: "/announcements",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Announcements /> }],
		},
		{
			path: "/:guideName/:guideId/settings",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <WebAppSettingspage /> }],
		},
		{
			path: "/tooltips",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Tooltip /> }],
	  	}, 
	  	{
			path: "/hotspots",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Hotspots/> }],
		},
		{
			path: "/banners",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Banners /> }],
		},
		{
			path: "/checklists",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Checklists /> }],
		},
		{
			path: "/surveys",
			element: <ProtectedRoute allowedUserTypes={["admin", "user"]} />,
			children: [{ path: "", element: <Surveys /> }],
		},
	]);

  return routes;
};

export default Routing;

