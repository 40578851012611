import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/AuthProvider";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import userManager from "../auth/UseAuth";
import { getOrganizationById } from "../../services/OrganizationService";
import { User } from "../../models/User";
import { Organization } from "../../models/Organization";
import jwt_decode from "jwt-decode";
import { LoginUserInfo } from "../../models/LoginUserInfo";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Homepageimg,
	Wavinghand,
} from "../../assets/icons/icons";
let OrganizationId: string;
let UserId: string;
let userDetails: User;
let OrgDetails: Organization;

const Home: React.FC = () => {
	const { signOut} = useAuth();
	const [loginUserDetails, setUserDetails] = useState<User | null>(null);
	const [organizationDetails, setOrganizationDetails] = useState<Organization | null>(null);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [userId, setUserId] = useState(String);
	const [organizationId] = useState(String);
	const [loginUserInfo, setLoginUserInfo] = useState<LoginUserInfo | undefined>(undefined);
	const location = useLocation();
	const Navigate = useNavigate();

	useEffect(() => {
		let token = localStorage.getItem("access_token");
		const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
		if (userInfo['oidc-info'] && userInfo['user'] && userInfo['orgDetails']) {
			userDetails = JSON.parse(userInfo['user'])
			OrgDetails = JSON.parse(userInfo['orgDetails'])
			OrganizationId = OrgDetails.OrganizationId;
			token = JSON.parse(userInfo['oidc-info']).access_token;
		}		
		const lastpath = localStorage.getItem("last_path") ;
		if (token) {
			try {				
				if (userDetails?.UserType === "SuperAdmin") {
					Navigate("/superadmin/organizations");
				}				
				const loggedinUserInfo = jwt_decode<LoginUserInfo>(token);
				
				if (lastpath && lastpath != "")
				{
					localStorage.setItem("last_path","");
					Navigate(lastpath);
				}
			} catch (error) {

				signOut();
			}
		}
		else {
			signOut();
		}
	}, [userDetails]);
const{t:translate}=useTranslation();
	return (
		<div
			className="qadpt-homepg"
		>
			<div className="qadpt-imgsec">
			 <img 
												src={Homepageimg} 
												alt="Homepage Logo" 
												
			/> </div>
			<div className="qadpt-des">
			<div className="qadpt-msg">{translate('Welcome Back,')}</div>
			{userDetails && (
				<div className="qadpt-usrnm">
						{userDetails.FirstName}
						<img 
												src={Wavinghand} 
												alt="Wavinghand" 
												
			/>
				</div>
				)}
				<div className="qadpt-statmsg"> "Ready to engage your users? Start creating easy Interactions with our Services"</div> </div>
			
		</div>
	);
};

export default Home;
export { OrganizationId, UserId };
