import React, { useState, useEffect } from "react";
import {
	DataGrid,
	GridColDef,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
	Button,
	Menu,
	MenuItem,
	TextField,
	Autocomplete,
	Container
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import loader from "../../assets/loader.gif";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { GetAuditLogsBySearch, GetAuditLogsByOrganizationId, fetchOrganizations } from "../../services/AuditLogServices";
import { SearchParams } from "../../models/SearchParams";
import { format } from 'date-fns';
import { Box,IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { fetchUsersList } from "../../services/UserService";
//import AuditlogCustomColumnMenu from "./AuditlogCustomColumnMenu";
import { OrganizationId } from "../common/Home";
import { useTranslation } from "react-i18next";
import { useAuth } from "../auth/AuthProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';




interface AuditLog {
	AuditLogId: string;
	ReferenceId: string;
	Name: string;
	AccountName: string;
	ReferenceType: string;
	Type: string;
	CreatedDate: string;
	IPAddress: string;
	Browser: string
	CreatedBy:string,
	
}
const categories={
	// "UserSession": [
	// 	"Login",
	// 	"Logout"
	// ],
	"Experiences": [
		"TourCreated",
		"TourUpdated",
		"TourDeleted",
		"TourPublished",
		"TourUnPublished",
		"AnnouncementCreated",
		"AnnouncementUpdated",
		"AnnouncementDeleted",
		"AnnouncementPublished",
		"AnnouncementUnPublished",
		"TooltipCreated",
		"TooltipUpdated",
		"TooltipDeleted",
		"TooltipPublished",
		"TooltipUnPublished",
		"BannerCreated",
		"BannerUpdated",
		"BannerDeleted",
		"BannerPublished",
		"BannerUnPublished",
		"ChecklistCreated",
		"ChecklistUpdated",
		"ChecklistDeleted",
		"ChecklistPublished",
		"ChecklistUnPublished",
		"SurveyCreated",
		"SurveyUpdated",
		"SurveyDeleted",
		"SurveyPublished",
		"SurveyUnPublished",
		"HotspotCreated",
		"HotspotUpdated",
		"HotspotDeleted",
		"HotspotPublished",
		"HotspotUnPublished"
	],
	"User": [
		"UserCreated",
		"UserUpdated",
		"UserDeactivated",
		"UserActivated",
		"UserLogin",
		"UserLogout"
	],
	"UserRole": [
		"UserRoleAssigned",
		// "UserRoleUpdated",
		"UserRoleDeleted"
	],
	"Account": [
		"AccountCreated",
		"AccountUpdated",
		"AccountDeleted",
	]
}

const AuditLogList: React.FC = () => {
	const { t: translate } = useTranslation();
	const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
	const [loading, setLoading] = useState(false);
	const [referenceTypes, setReferenceTypes] = useState<string[]>([]);
	const [allAuditLogData, setAllAuditLogData] = useState<AuditLog[]>([]);
	const [typeOptions, setTypeOptions] = useState<string[]>([]);
	const [organizations, setOrganizations] = useState<any[]>([]);
	const [models, setModels] = useState("");
	const [isClearing, setIsClearing] = useState(false);
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [orderByFields, setOrderByFields] = useState("");
	const [filters, setFilters] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const [searchText, setSearchText] = useState("");
     const [types, setTypes] = useState<string[]>([]);
	const [environment, setEnvironment] = useState<string>("");
	const [type, setType] = useState<string | null>(null);
	const [referenceType, setReferenceType] = useState<string | null>(null);
	const [name, setName] = useState<string>("");	
	const [user, setUser] = useState<string | null>(null);
    const [totalcount, setTotalcount] = useState(0);
	const [createdUser, setCreatedUser] = useState<string>('');
	const [nameFilter, setNameFilter] = useState<string>('');
	const [eventType, setEventType] = useState<string>('');	
	const { signOut, userDetails } = useAuth();
	const [userType, setUserType] = useState(userDetails?.UserType ?? "");
	const [organizationId, setOrganizationId] = useState(userDetails?.OrganizationId ?? "");
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 15,
	});
	const formatIndianDateTime = (dateString: string) => {
		return format(new Date(dateString), 'MM-dd-yyyy HH:mm:ss');
	};

	const columns: GridColDef[] = [
		{ field: "ReferenceType", headerName: "Category", width: 160,disableColumnMenu: true },
		{field:"Type",headerName:"Event Type",width:130,disableColumnMenu: true},
		{ field: "Name", headerName: "Name", width: 110,disableColumnMenu: true },
		{ field: "AccountName", headerName: "Account Name", width: 160,disableColumnMenu: true },
		{field:"IPAddress", headerName:"IP Address", width:110,disableColumnMenu: true},
		{ field: "Browser", headerName: "Browser", width: 100,disableColumnMenu: true },
		{ field: "CreatedBy", headerName: "Created By", width: 130,disableColumnMenu: true},
		{ field: "CreatedDate", headerName: "Created On", width: 160,disableColumnMenu: true},
	];
	
	const [allAuditLogs, setAllAuditLogs] = useState<AuditLog[]>([]);


	useEffect(() => {
		if (organizationId) {
		  const fetchAndSetUsers = async () => {
			await fetchUsersList(
			  setModels, // Function to set models
			  setLoading, // Function to set loading state
			  organizationId, // Organization ID
			  0, // Initial skip
			  15, // Limit for top
			  setTotalcount, // Function to set total count
			  '', // Default ordering field
			  [] // Default filters
			);
		  };
	  
			fetchAndSetUsers();
			fetchFilteredData(organizationId);
			setOrganizationId(organizationId);
		}
	  }, [organizationId]); // Dependency array should only include variables that trigger data fetch
	//   useEffect(() => {
	// 	if (Array.isArray(models)) {
	// 	  // Check the structure of a single model
	  
	// 	  // Extract usernames
	// 	  const usernames = models.map(user => user.EmailId); // Adjust the field name if necessary
	// 	  setUsers(usernames);
	// 	} else {
	// 	  console.error('Models is not an array:', models);
	// 	}
	//   }, [models]); // Dependency array includes models to handle updates
	// useEffect(() => {
	// 	// Set default dates to last 2 days
	// 	const today = new Date();
	// 	const defaultFromDate = new Date(today);
	// 	defaultFromDate.setDate(today.getDate() - 2);

	// 	setFromDate(defaultFromDate);
	// 	setToDate(today);
	// }, []);
	const today: Dayjs = dayjs(); // Always use Dayjs
	const defaultFromDate: Dayjs = today.subtract(2, 'day');

const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs(defaultFromDate).startOf('day'));
const [toDate, setToDate] = useState<Dayjs | null>(dayjs(today).endOf('day'));


	

	useEffect(() => {
		if (referenceType && categories[referenceType as keyof typeof categories]) {
		  setTypeOptions(categories[referenceType as keyof typeof categories]);
		} else {
		  setTypeOptions([]);
		}
		setType("");
	}, [referenceType]);
	useEffect(() => {
		const fetchAndSetOrganizations = async () => {
		  const orgData = await fetchOrganizations(setOrganizations, setLoading);
		  if (orgData) {
			setOrganizations(orgData);
			  // Find "Quixy" and set it as the default selected organization
			const defaultOrganization = orgData.find((org:any) => org.Name === "Quixy");
			if (defaultOrganization) {
			  setOrganizationId(defaultOrganization.OrganizationId);
			}
		  }
		};
	  
		fetchAndSetOrganizations();
	  }, []);
// Empty dependency array to run only once on component mount
useEffect(() => {
	// Fetch data only if not in clearing mode
	fetchFilteredData(organizationId);

	// if (!isClearing) {
	//   fetchFilteredData(organizationId);
	// } else {
	//   setIsClearing(false);
	// }
}, [organizationId, paginationModel]);

  const fetchFilteredData = async (organizationId: any) => {
	if (!organizationId) {
	  console.error("Organization ID is required to fetch data");
	  return;
	}
  
	try {
	  // Set loading to true before the request
	  setLoading(true);
  
	  // Calculate skips and tops based on pagination model
	  const skipcount = paginationModel.pageSize || 15;
	  const limitcount = paginationModel.page * skipcount;
	  const skips = limitcount;
	  const tops = paginationModel.pageSize;
  
	  // Set skip and top state
	  setskip(skips);
	  settop(tops);
  
	  // Fetch audit logs with the adjusted function call
	  const response = await GetAuditLogsBySearch(
		skips,
		tops,
		organizationId,
		orderByFields,
		filters,
		fromDate?.toISOString(),
		toDate?.toISOString(),
		setTotalcount,
		  setAuditLogs,
		  type,
		  referenceType,
		  nameFilter,
		createdUser
	  );
  
	  if (response && Array.isArray(response)) {
		// Process the response data
		const auditLogData: AuditLog[] = response.map((log: any) => ({
		  AuditLogId: log.AuditLogId,
		  ReferenceId: log.ReferenceId,
			Name: log.Name,
		  AccountName: log.AccountId,
		  OrganizationId: log.OrganizationId,
		  ReferenceType: log.ReferenceType,
		  Type: log.Type,
		  CreatedBy: log.CreatedBy,
		  Browser: log.Browser,
			IPAddress: log.IPAddress,
		  
		  CreatedDate: formatIndianDateTime(log.CreatedDate),
		}));
  
		setAuditLogs(auditLogData);
	  } else {
		console.error("Invalid response format:", response);
	  }
	} catch (error) {
	  console.error('Error fetching audit logs:', error);
	} finally {
	  setLoading(false);
	}
  };
  
const handleSearch = async (organizationId: any) => {	
	if (!organizationId) {
	  //console.error("Organization ID is required to perform search");
		return;
	}
	try {
	  // Set loading state to true before fetching data
	  setLoading(true);
  
	  // Calculate skips and tops based on pagination model
	  const skipcount = paginationModel.pageSize || 15;
	  const limitcount = paginationModel.page * skipcount;
	  const skips = limitcount;
	  const tops = paginationModel.pageSize;
  
	  // Set skip and top state
	  setskip(skips);
	  settop(tops);
  
	  // Fetch audit logs using the GetAuditLogsBySearch function
	  const response = await GetAuditLogsBySearch(
		skips,
		tops,
		organizationId,
		orderByFields,
		filters,
		fromDate?.toISOString(),
		toDate?.toISOString(),
		setTotalcount,
		  setAuditLogs,
		  type,
		  referenceType,
		  name,
		createdUser
	  );
  
	  if (response && Array.isArray(response)) {
		// Process the response data
		const auditLogData: AuditLog[] = response.map((log: any) => ({
		  AuditLogId: log.AuditLogId,
		  ReferenceId: log.ReferenceId,
			Name: log.Name,
		  AccountName: log.AccountId,
		  OrganizationId: log.OrganizationId,
		  ReferenceType: log.ReferenceType,
		  Type: log.Type,
		  CreatedBy: log.CreatedBy,
		  Browser: log.Browser,
		  IPAddress: log.IPAddress,
		  CreatedDate: formatIndianDateTime(log.CreatedDate),
		}));
  
		setAuditLogs(auditLogData);
	  } else {
		console.error("Invalid response format:", response);
	  }
	} catch (error) {
	  console.error("Error fetching audit logs:", error);
	} finally {
	  setLoading(false);
	}
  };
  

	


	const handleDownloadExcel = async () => {
		// Implement the download Excel functionality here
	};

	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</GridToolbarContainer>
		);
	};

	useEffect(() => {
		subscribe(() => {
			setSidebarOpen(isSidebarOpen());
		});
	}, []);

	return (
		<Container>
   <div className="qadpt-midpart setng-box">
      <div className="qadpt-content-block">
         <div className="qadpt-head">
            <div className="qadpt-title-sec">
							<div className="qadpt-title"> {translate('Activity Logs')}</div>
            </div>
         </div>
         <div className="grid-toolbar-options">
            <div className="left-options">
               <div className="drp-fields">
                  <Autocomplete className="auto-filed"
                     options={Object.keys(categories)}
                     value={referenceType}
                     onChange={(event, newValue) =>
                  setReferenceType(newValue)}
                  renderInput={(params) => (
                  <TextField className={`qadpt-activty-category ${
                  referenceType ? 'visible' : 'hidden' }`}
                  {...params}
						  label={translate('Category')}
                  InputProps={{
                  ...params.InputProps,									
                  }}
                  />
                  )}	/>
                  <Autocomplete className="auto-filed"
                     options={typeOptions}
                     value={type}
                     onChange={(event, newValue) =>
                  setType(newValue)}
                  renderInput={(params) => (
                  <TextField className={`qadpt-activty-category ${
                  referenceType ? 'visible' : 'hidden'}`}
                  {...params}
						  label={translate('Event Type')}
                  InputProps={{
                  ...params.InputProps,								
                  }}
                  />
                  )}/>
               </div>
               <div  className="dt-fields">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DateTimePicker className={`qadpt-DateTime ${fromDate ? '' : 'hide-close'}`}
										label={translate('From Date')}
										views={['year', 'month', 'day']}
                        value={fromDate}
										onChange={(newValue) =>
                     setFromDate(newValue ? dayjs(newValue) : null)}
                     maxDateTime={toDate ?? undefined}
					
					slotProps={{
						textField: {
						  className: "qadpt-datepicker", 
						},
						field: { clearable: true },
					}}
                     />
                     <DateTimePicker className={`qadpt-DateTime dt-fld2 ${fromDate ? '' : 'hide-close'}`}
										label={translate('To Date')}
										value={toDate}
										views={['year', 'month', 'day']}
                        onChange={(newValue) =>
                     setToDate(newValue ? dayjs(newValue).endOf('day') : null)}
                     minDateTime={fromDate ?? undefined}
					
					slotProps={{
						textField: {
							className: "qadpt-datepicker", 
						  },
						  field: { clearable: true },
					}}
                     />
                  </LocalizationProvider>
               </div>
               <TextField className="name-fld"
                  variant="outlined"
								label={translate('Name')}
                  value={name}
                  onChange={(e) =>
               {
               setName(e.target.value);
               setNameFilter(e.target.value); // Update nameFilter when name changes
               }}
               />
            </div>
            <div className="right-options">					
            <Button
               variant="outlined"
               color="primary"
               onClick={() => {
            // Immediately clear the audit logs data
            // setAuditLogs([]); 
            // Set clearing mode to true
            setIsClearing(true);
            // Reset state and data
            setEnvironment("");
            setType("");
            setReferenceType("");
            setName("");
            setNameFilter("");
            setFromDate(null);
            setToDate(null);
            setUser("");
            // Reset pagination without triggering data fetch
            setPaginationModel({ page: 0, pageSize: 15 });
            }}>
								{translate('Clear')}
            </Button>
            <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch(organizationId)}
            disabled={environment === "" && 
            (type === "" || type === null)
            && (referenceType === "" || referenceType === null) 
            && fromDate === null
            && toDate === null
            && user === "" && name === ""}					>
								{translate('Search')}
            </Button>
         </div>
      </div>
      {loading ? (
      <div className="Loaderstyles">
      <img
      src={loader}
			alt="Spinner"
			className="LoaderSpinnerStyles"
      />
   </div>
   ) : (
   <DataGrid
    className="qadpt-audit-grd"

   rows={auditLogs}
   columns={columns}
   getRowId={(row) => row.AuditLogId}
   paginationModel={paginationModel}
   onPaginationModelChange={setPaginationModel}
   pagination
   paginationMode="server"
   rowCount={totalcount}
   pageSizeOptions={[15, 25, 50, 100]}
   localeText={{
   MuiTablePagination: {
   labelRowsPerPage: "Records Per Page",
   },
   }}
   disableRowSelectionOnClick={true}
   loading={loading}
   />
   )}
   </div>
   </div>
</Container>
	);
};

export default AuditLogList;

