import React, { useState, useEffect,useRef, startTransition, useContext } from "react";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from "react-router-dom";
import i18n from '../multilingual/i18n';
import { SAinitialsData } from "../login/Superadminloginpage";
import { Popover, Typography, Button, Avatar, Box } from '@mui/material';
import { initialsData } from "../auth/AuthProvider";
import { initialssData } from "../settings/ProfileSettings";
import SideMenu from "./sideMenu";
import { quickadopt } from "../../assets/icons/icons";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Popup from "../common/Popup";
import { FormControl, InputLabel, InputAdornment, IconButton, MenuItem, Grid } from "@mui/material";
import Select from "@mui/material/Select";
import LanguageIcon from '@mui/icons-material/Language';
import { translateText } from "../multilingual/Translator"; 
import Translater from "../multilingual/Multilingual";
import { useLocation } from "react-router-dom";
import { LanguageProvider } from "../multilingual/LanguageContext";
import ProfileSettings from "../settings/ProfileSettings";
import Settings from "../settings/Settings";
import AccountSettings from "../settings/AccountSettings";
import { getLabels, getLanguages, getLanguagesByOrganization, getPlatformLabels } from "../../services/MultilingualService";
import { setSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { Sidebar } from "../common/Sidebar";
import PageWrapper from "../pagewrapper";
import { Feedback, Logout, settings,logo ,global,profile,ArrowDown} from "../../assets/icons/icons";
import { isSidebarOpen } from "../adminMenu/sidemenustate";
import TeamSettings from "../settings/TeamSettings";
import RightSettings from "../settings/RightSettings";
import UserList from "../user/UserList";
import LogoutPopup from "./logoutpopup";
import AccountList from "../account/AccountList";
import userManager from '../auth/UseAuth';
import DomainSettings from "../settings/DomainSettings";
import AlertSettings from "../settings/AlertSettings";
import BillingSettings from "../settings/BillingSettings";
import CodeInstall from "../settings/InstallSettings";
import { useAuth } from "../auth/AuthProvider";
import AuditLogList from "../auditLog/AuditLogList";
import { User } from "../../models/User";
import ShareFeedbackPopup from "../feedback/ShareFeedbackPopup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { GetAccountsList } from "../../services/GuideService";
import { AccountContext } from "../account/AccountContext";
import { userApiService, userUrl } from "../../services/APIService";
let accountId: string;
interface Account {
	AccountId: string;
	AccountName: string;
}
//let organizationLanguages : OrganizationLanguage[] = [];
export interface Language {
	LanguageId: string;
	Language: string;
	LanguageCode: string;
	FlagIcon: string;
}
export const languageDisplayMap: { [key: string]: string } = {
	English: 'English',
	Telugu: 'తెలుగు',
	Hindi: 'हिन्दी',
	Arabic: 'العربية',

  };

type OrganizationLanguage = {
	OrganizationId: string | undefined;
	LanguageId: string ;
	Language: string;
	Id: string;
  };

const OrgAdminMenu: React.FC = () => {
	const { t: translate } = useTranslation();
	const [isShareFeedbackPopup, setIsShareFeedbackPopup] = useState(false);
	const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  
    const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [openMenu, setOpenMenu] = useState(false);
	const { signOut, userDetails } = useAuth();
	const [userType, setUserType] = useState(userDetails?.UserType ?? "");
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId ?? "");
	const [user, setUser] = useState<User | null>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const [openPopup, setOpenPopup] = useState(false);
	const [sidebarLocalOpen, setLocalSidebarOpen] = useState(isSidebarOpen());
	const navigate = useNavigate();
	const [toLanguage, setToLanguage] = useState<string>(() => localStorage.getItem('selectedLanguage') || 'English'); // Get initial language from localStorage
	const [translatedLabels, setTranslatedLabels] = useState<string[]>([]);
	const location = useLocation();
	const [languages, setLanguages] = useState<Language[]>([]); 
	const [loading, setLoading] = useState(true); 
	const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
	const [organizationLanguages, setOrganizationLanguages] = useState<OrganizationLanguage[]>([]);
	const ORGANIZATION_ID = userDetails?.OrganizationId; 
	const [accounts, setAccounts] = useState<Account[]>([]);   
	const [selectedAccountId, setSelectedAccountId] = useState('');  
	const { setAccountId } = useContext(AccountContext); 
	const firstDropdownRef = useRef<HTMLDivElement | null>(null);
	const secondDropdownRef = useRef<HTMLDivElement | null>(null);

	const handleDropdownOpen = (dropdownName: string) => {
	  setOpenDropdown(dropdownName === openDropdown ? null : dropdownName);
	};
  

	const handleClickOutside = (event: MouseEvent) => {
	  if (
		firstDropdownRef.current && !firstDropdownRef.current.contains(event.target as Node) &&
		secondDropdownRef.current && !secondDropdownRef.current.contains(event.target as Node)
	  ) {
		setOpenDropdown(null);
	  }
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, []);
	useEffect(() => {
		if (userDetails && userDetails.OrganizationId && userDetails?.UserType?.toLocaleLowerCase()==='admin') {
			const fetchAccounts = async () => {
				setLoading(true);
				try {
					await GetAccountsList(setAccounts, setLoading, ORGANIZATION_ID, -1, -1, "", "", "");
					
				} catch (err) {
					console.error(err);
				} finally {
					setLoading(false);
				}
			};
  
			fetchAccounts();
		}
	}, [ORGANIZATION_ID]);

	const handleAccountChange = (event:any) => {
		const AccountId = event.target.value; 
		setSelectedAccountId(AccountId);
		setAccountId(AccountId);
		accountId = AccountId;
		localStorage.setItem("CurrentAccountId",accountId )
	  };
	useEffect(() => {

		  const fetchLanguagesByOrganization = async () => {
			try {
				const response = await getLanguagesByOrganization(ORGANIZATION_ID);
				setUserType(userDetails?.UserType?.toLocaleLowerCase() ?? '');	
				setOrganizationLanguages(response.data);
				setLoading(false);
			} catch (err) {
			  console.error('Error fetching languages:', err);
			  setLoading(false);
			}
		  };
	  
		  fetchLanguagesByOrganization();
		
	  }, []); 
	  
	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString &&  userInfoString !='{}') { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					setUser(parsedUser);	
					if (parsedUser) {
						setUserType(parsedUser.UserType?.toLocaleLowerCase() ?? '');
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		else if (userDetails) {
			setUser(userDetails);	
			if (userDetails) {
				setUserType(userDetails.UserType?.toLocaleLowerCase() ?? '');
				const OrgId = userDetails.OrganizationId ?? '';
				setOrganizationId(OrgId);
			}
		}
	}, []);
	const ShareFeedbackClick = () => {
		setIsShareFeedbackPopup(true);
		
	  };
	  
	  const closeShareFeedback = () => {
		  setIsShareFeedbackPopup(false);
		 
	  };
	useEffect(() => {
		const storedSelectedLanguages = localStorage.getItem("selectedLanguages");
		if (storedSelectedLanguages) {
			setSelectedLanguages(JSON.parse(storedSelectedLanguages));
		}
	}, []);
	

	const getLanguageName = (languageCode: string): string => {
		const language = languages.find((lang) => lang.LanguageCode === languageCode);
		return language ? language.Language : "Unknown";
	};

	const handleToLanguageChange = (event:any) => {
		const selectedLanguage = event.target.value;
		setToLanguage(selectedLanguage);
		let languageKey = '';
		if (selectedLanguage === 'Hindi') {
		  languageKey = 'hi';
		} else if (selectedLanguage === 'Telugu') {
		  languageKey = 'te';
		} else if (selectedLanguage === 'Arabic') {
		  languageKey = 'ar';
		}
		localStorage.setItem('selectedLanguage', selectedLanguage); 

	};
	
	const [labelsNew, setLabelsNew] = useState<any>({});
	useEffect(() => {
		const fetchLabelsNew = async (toLanguage: any) => {
		  if (toLanguage === "English") {
			i18n.changeLanguage('en');
			return;
		  }
	  
		  try {
			setLoading(true);
			const data = await getLabels(toLanguage);
			if (data) {
			  let parsedLabelsNew;
			  let languageKey = '';
	  
			  if (toLanguage === "Hindi") {
				parsedLabelsNew = data.hi;
				languageKey = 'hi';
			  } else if (toLanguage === "Telugu") {
				parsedLabelsNew = data.te;
				languageKey = 'te';
			  } else if (toLanguage === "Arabic") {
				parsedLabelsNew = data.ar;
				languageKey = 'ar';
			  }
	  
			  if (parsedLabelsNew) {
				setLabelsNew(parsedLabelsNew);
				i18n.addResourceBundle(languageKey, 'translation', parsedLabelsNew, true, true);
				i18n.changeLanguage(languageKey);
			  }
			}
		  } catch (err) {
			console.error('Error fetching labels:', err);
		  } finally {
			setLoading(false);
		  }
		};
	  
		fetchLabelsNew(toLanguage);
	  }, [toLanguage]);
	  
  
	const handleMenuClick = () => {
		setOpenMenu((prev) => !prev);
	};

	useEffect(() => {
		const unsubscribe = subscribe(setLocalSidebarOpen);
		return () => unsubscribe();
	}, []);

	const handleHomeClick = () => {
		startTransition(() => {
			navigate("/");
		});
	};

	const handleTogglePopover = (event:any) => {
		if (anchorEl) {
		  // Close the popover if it's open
		  setAnchorEl(null);
		} else {
		  // Open the popover and set the anchor element
		  setAnchorEl(event.currentTarget);
		}
	  };

	const handleOrgClick = () => {
		startTransition(() => {
			navigate("/organizations");
		});
	};

	const handleAccountClick = () => {
		startTransition(() => {
			navigate("/accounts");
		});
	};

	const handleGuideClick = () => {
		startTransition(() => {
			navigate("/guide");
		});
	};

	const handleAccSeetingsClick = () => {
		startTransition(() => {
			navigate("/settings/account");
		});
	};

	const handleTeamClick = () => {
		startTransition(() => {
			navigate("/settings/team");
		});
	};

	const handleThemesClick = () => {
		startTransition(() => {
			navigate("/theme");
		});
	};

	const handleBillingClick = () => {
		startTransition(() => {
			navigate("/settings/billing");
		});
	};

	const handleInstallClick = () => {
		startTransition(() => {
			navigate("/settings/install");
		});
	};

	const handlenotifyClick = () => {
		startTransition(() => {
			navigate("/notifications");
		});
	};

	const handleProfileClick = () => {
		
			navigate("/viewprofile");
	
		setAnchorEl(null);
	};

	const handleClick = (path: string) => {
		switch (path) {
			case "user":
				return navigate("/user");
			case "account":
				return navigate("/settings/account");
			case "settings":
				return navigate("/settings");
			case "auditlogs":
				return navigate("/superadmin/auditlogs");
			case "organization":
				return navigate("/superadmin/organizations");
			case "multilingual":
				return navigate("/superadmin/Multilingual");
			default:
				return navigate("/");
		}
	};

		

	const handleQuickAdoptClick = () => {
		const logedUsertype = localStorage.getItem('userType');
		if (logedUsertype !== "SuperAdmin") {
			startTransition(() => {
				navigate("/");
			});
		}
	};
	useEffect(() => {
		if (accounts.length > 0) {
			setSelectedAccountId(accounts[0].AccountId); 
			setAccountId(accounts[0].AccountId)
			accountId = accounts[0].AccountId
			localStorage.setItem("CurrentAccountId",accountId )

		}
	  }, [accounts]);
	const handleLogoutClick = () => {
		setOpenPopup(true);
		setAnchorEl(null);
	};
	const toggleSidebar = () => {
		setSidebarOpen(!sidebarLocalOpen);
	};
	return (
		<div>
			<div className="qadpt-banner">
				<div>					
					<div className="adapat-banner-left" >
					<div>
    <img 
        src={logo} 
        alt="QuickAdopt Logo" 
        className="qadpt-logo" 
    />
</div>
					</div>
					
				</div>
				<div className="adapat-banner-right">
				{openMenu && userType.toLocaleLowerCase() === "superadmin" ? (
						<div className="qadpt-settings-container">
							<a								
								onClick={() => handleClick("organization")}
								className="qadapt-link"
							>
								Organization
							</a>
							<a
								onClick={() => handleClick("auditlogs")}
								className="qadapt-link"
							>
								Auditlogs
							</a>

							<a
								onClick={() => handleClick("multilingual")}
								className="qadapt-link"
							>
								Language Settings
							</a>
						</div>
					) : null}
					{userType.toLocaleLowerCase() === "superadmin" && (
					<i className="fal fa-admenu menu-icon"
						data-testid="cog-tci"
						onClick={handleMenuClick}>
					</i>
					)}
					{/* <i className="fal fa-bell menu-icon"></i> */}
					{userType !== "superadmin" ? (

<div className="qxy-lang" style={{width:"auto"}} >
<FormControl variant="standard" className="lang-input">
<Select
value={selectedAccountId}
									onChange={handleAccountChange}
									
displayEmpty
									fullWidth
									startAdornment={
										<InputAdornment position="start">
										 <img 
																				  src={profile} 
																				  alt="profile Logo" 
																				  
											  />
										</InputAdornment>
									  }
									  endAdornment={
										<InputAdornment position="end">
										 <img 
											src={ArrowDown} 
											alt="Arrowdown Logo" 
												  style={{ cursor: 'pointer', zoom: "0.9", transform: openDropdown === "first" ? 'rotate(180deg)' : 'rotate(0deg)',
													transition: 'transform 0.3s ease' }} 
												  onClick={(e) => {
													e.stopPropagation(); // Prevent default select behavior if needed
													handleDropdownOpen("first"); // Trigger dropdown open
												  }}	  
											  />
										  </InputAdornment>
									  }
									open={openDropdown === "first"}
            onClose={() => setOpenDropdown(null)}
									onOpen={() => handleDropdownOpen("first")}
									IconComponent={() => null} 
									sx={{
										"&:before, &:after": {
										  display: "none",
										},
									  }}
MenuProps={{
    PaperProps: {
      sx: {
        width: '200px', // Dropdown width
        '& .MuiMenuItem-root': {
          fontSize: '14px', // Font size for dropdown items
          whiteSpace: 'normal', // Text wrapping
          wordBreak: 'break-word', // Word breaking
        },
      },
    },
  }}
>
{accounts.length > 0 ? (
accounts.map((account) => (
<MenuItem key={account.AccountId} value={account.AccountId} >
{account.AccountName}
</MenuItem>
))
) : (
<MenuItem disabled>No accounts available</MenuItem>
)}
		</Select>
		</FormControl>
	</div>
	) : null}

			
					{location.pathname !== "settings/multilingual" && userType !== "superadmin" ? (
						<div className="qxy-lang">
						<Box>
						<FormControl variant="standard" className="lang-input">
  <InputLabel id="demo-simple-select"></InputLabel>
  <Select
    className="lang-input-box"
    labelId="demo-simple-select"
    id="demo-simple"
    value={toLanguage} // Controlled component
    onChange={handleToLanguageChange}
    startAdornment={
      <InputAdornment position="start">
        <img src={global} alt="global Logo" />
      </InputAdornment>
    }
    endAdornment={
      <InputAdornment position="end"> 
        <img
          src={ArrowDown}
          alt="Arrowdown Logo"
          style={{
            cursor: 'pointer',
            zoom: '0.9',
            transform: openDropdown === "second" ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent default select behavior
            setOpenDropdown(openDropdown === "second" ? null : "second"); // Toggle logic
          }}
        />
      </InputAdornment>
    }
    IconComponent={() => null} 
    open={openDropdown === "second"}
    onClose={() => setOpenDropdown(null)}
    onOpen={() => handleDropdownOpen("second")}
    sx={{
      "&:before, &:after": {
        display: "none",
      },
    }}
  >
    <MenuItem key="en" value="English">
      English
    </MenuItem>
    {organizationLanguages.map((lang) => (
      <MenuItem key={lang.LanguageId} value={lang.Language} sx={{ fontSize: '14px' }}>
        {languageDisplayMap[lang.Language] || lang.Language}
      </MenuItem>
    ))}
  </Select>
</FormControl>


</Box>

						</div>
					) : null}
					<div
						className="qadpt-prof"
						onClick={handleTogglePopover}
					>
						<span style={{ display: "flex", marginRight: "0.5rem !important" }}>
							<span>
								<div className="prof-div">
									<span className="prof-span">{initialssData ? initialssData : initialsData}</span>
								</div>
							</span>
						</span>
					</div>
					<div className="profilepopup">
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							//onClose={() => setAnchorEl(null)}
							onClose={handleTogglePopover}
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							PaperProps={{
								style: {
									marginTop: '32px',
								},
							}}
							sx={{
								"& .MuiPaper-root": {
									width: "195px",
									height: "auto",
									borderRadius: "7px",
									padding: "10px 12px",
									backgroundColor: "#ffffff",
									boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
									zIndex: 9999,
								},
							}}
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								p={1}  
							>
								<Box
									width="100%"
								>
									<Button
										fullWidth
										onClick={ShareFeedbackClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<img
											src={Feedback}
											alt="Feedback Icon"
											style={{ width: '18px', height: '18px', marginRight: '8px' }}
										/>
										<Typography
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											 {translate('Share Feedback')}
										</Typography>
									</Button>
									<Button
										fullWidth
										onClick={handleProfileClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<AccountCircleOutlinedIcon style={{ color: 'black' }} />
										<Typography 
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											{translate('View Profile')}
										</Typography>
									</Button>
									<Button
										fullWidth
										onClick={handleLogoutClick}
										sx={{
											width: '171px',
											height: '44px',
											padding: '10px 0',
											gap: '10px',
											justifyContent: 'flex-start',
											textTransform: 'none',
										}}
									>
										<img
											src={Logout}
											alt="Logout Icon"
											style={{ width: '18px', height: '18px', marginRight: '8px' }}
										/>
										<Typography
											sx={{
												fontFamily: 'Poppins',
												fontSize: '14px',
												fontWeight: 400,
												lineHeight: '21px',
												letterSpacing: '0.3px',
												textAlign: 'left',
												color: '#202224',
											}}
										>
											{translate('Logout')}
										</Typography>
									</Button>
								</Box>
							</Box>
						</Popover>
					</div>
				</div>
			</div>
			<ShareFeedbackPopup
        open={isShareFeedbackPopup}
		onClose={closeShareFeedback}
		setIsShareFeedbackPopup={setIsShareFeedbackPopup}
      />
			<div className="qadpt-page-content">
			{userType.toLocaleLowerCase() === "admin" ? <SideMenu selectedLanguageProp={toLanguage} /> : null}
			{openPopup ? (
				<LogoutPopup
				onClose={() => setOpenPopup(false)}
				onOk={async () => {
					setOpenPopup(false);
					
					if (userDetails?.UserType !== "SuperAdmin") {
						try {
							const response = await userApiService.get(`/User/Logout`);
							if (response.status === 401) {
								localStorage.clear();
								sessionStorage.clear();
								navigate("/login")
							}
							signOut();
						} catch (error) {
							
							
						}
					} else {
						
						localStorage.clear();
						sessionStorage.clear();
						document.cookie.split(";").forEach(cookie => {
							const [name] = cookie.split("=");
							document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
						});
						navigate("/admin/adminlogin");
					}
				}}
				title={translate('Confirmation')}
				description={translate('Are you sure you want to logout?')}
				button1={translate('Cancel')}
				button2={translate('Logout')}
			/>
			
			) : null}
<div
  className={`qadpt-settings-content ${
    (
      [
        `/${userDetails?.OrganizationId}/accounts`,
        `/${userDetails?.OrganizationId}/roles`,
        `/${userDetails?.OrganizationId}/team`,
        "/settings/domains",
        "/settings/rights",
        "/settings/alerts",
        "/settings/billing",
		"/settings/install",
							"/settings/activitylog",
		"/settings/multilingual"
      ].includes(location.pathname) && userType === "admin"
    ) ? '' : 'qadpt-hide-smenu'
  }`}
>
			{location.pathname === `/${userDetails?.OrganizationId}/accounts` && userType === "admin" ? (
			<div className="qadpt-settings-page">
			<Box>
							<PageWrapper>
								<div className="qadpt-settings-smenu">
					<Box id="settingMenuBox">
						<Settings />
					</Box>
								</div>
								<div className="usr-list-page">
					<Box>
						<AccountList />
								</Box>
								</div>

				</PageWrapper>
						</Box>
						</div>

		) : null}


			{location.pathname === "/viewprofile" ? (
				<PageWrapper>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<div style={{ width: '95%' }}>
							<ProfileSettings selectedLanguageProp={getLanguageName(toLanguage)} />
						</div>
					</div>
				</PageWrapper>
			) : null}
			
			{location.pathname === `/settings/multilingual` && userType === "admin"  ? (
			<div className="qadpt-settings-page">
				<Box>
								<PageWrapper>
									<div className="qadpt-settings-smenu">
						<Box id="settingMenuBox">
							<Settings />
						</Box>
									</div>
									<div className="usr-list-page">
						<Box>
						<LanguageProvider>
					<Translater selectedLanguageProp={toLanguage} />
				</LanguageProvider>
									</Box>
									</div>

					</PageWrapper>
							</Box>
							</div>

					) : null}
					
					{location.pathname === `/${userDetails?.OrganizationId}/team` && userType === "admin" ? (
			<div className="qadpt-settings-page">
				<Box>
								<PageWrapper>
									<div className="qadpt-settings-smenu">
						<Box id="settingMenuBox">
							<Settings />
						</Box>
									</div>
									<div className="usr-list-page">
						<Box>
							<UserList />
									</Box>
									</div>

					</PageWrapper>
							</Box>
							</div>

			) : null}

					{location.pathname === `/${userDetails?.OrganizationId}/roles` && userType === "admin" ? (
						<div className="qadpt-settings-page">
						<Box>
										<PageWrapper>
											<div className="qadpt-settings-smenu">
								<Box id="settingMenuBox">
									<Settings />
								</Box>
											</div>
											<div className="usr-list-page">
								<Box>
								<TeamSettings />
											</Box>
											</div>
			
							</PageWrapper>
									</Box>
									</div>
  				
			) : null}

					{location.pathname === "/settings/domains" && userType === "admin" ? (
						
						<div className="qadpt-settings-page">
						<Box>
										<PageWrapper>
											<div className="qadpt-settings-smenu">
								<Box id="settingMenuBox">
									<Settings />
								</Box>
											</div>
											<div className="usr-list-page">
								<Box>
								<DomainSettings />
											</Box>
											</div>
			
							</PageWrapper>
									</Box>
									</div>


			) : null}
					{location.pathname === "/settings/rights" && userType === "admin" ? (
			
			<div className="qadpt-settings-page">
			<Box>
							<PageWrapper>
								<div className="qadpt-settings-smenu">
					<Box id="settingMenuBox">
						<Settings />
					</Box>
								</div>
								<div className="usr-list-page">
					<Box>
					<RightSettings />
								</Box>
								</div>

				</PageWrapper>
						</Box>
						</div>
			) : null}

					{location.pathname === "/settings/alerts" && userType === "admin" ? (
						<div className="qadpt-settings-page">
						<Box>
										<PageWrapper>
											<div className="qadpt-settings-smenu">
								<Box id="settingMenuBox">
									<Settings />
								</Box>
											</div>
											<div className="usr-list-page">
								<Box>
								<AlertSettings />
											</Box>
											</div>
			
							</PageWrapper>
									</Box>
									</div>
			) : null}
					{location.pathname === "/settings/billing" && userType === "admin" ? (
			<div className="qadpt-settings-page">
			<Box>
							<PageWrapper>
								<div className="qadpt-settings-smenu">
					<Box id="settingMenuBox">
						<Settings />
					</Box>
								</div>
								<div className="usr-list-page">
					<Box>
					<BillingSettings />
								</Box>
								</div>

				</PageWrapper>
						</Box>
						</div>
			) : null}
					{location.pathname === "/settings/install" && userType === "admin" ? (
						<div className="qadpt-settings-page">
						<Box>
										<PageWrapper>
											<div className="qadpt-settings-smenu">
								<Box id="settingMenuBox">
									<Settings />
								</Box>
											</div>
											<div className="usr-list-page">
								<Box>
								<CodeInstall/>
											</Box>
											</div>
			
							</PageWrapper>
									</Box>
									</div>
  			
					) : null}
						{location.pathname === "/settings/activitylog" && userType === "admin" ? (
						<div className="qadpt-settings-page">
						<Box>
										<PageWrapper>
											<div className="qadpt-settings-smenu">
								<Box id="settingMenuBox">
									<Settings />
								</Box>
											</div>
											<div className="usr-list-page">
								<Box>
								<AuditLogList/>
											</Box>
											</div>
			
							</PageWrapper>
									</Box>
									</div>
  			
					) : null}
					
			</div>
			</div>
			</div>
	);
};

export default OrgAdminMenu;

export { accountId }